<template>
  <div class="login_conanter flexLinee">
    <div class="login_box w1200">
      <img
        src="../../image/logo.png"
        alt=""
        srcset=""
        class="login_box_img"
      >
        <div class="login_box_right">
          欢迎注册
        </div>
    </div>
    <!-- 注册 -->
    <div class="login_banner">
      <img
        class="login_banner_img"
        src="../../image/loginBanner.png"
        alt=""
        srcset=""
      >
        <!-- 注册 -->
        <div
          class="login_banner_box"
          v-if="register"
        >
          <div class="login_banner_nav">
            <div class="login_banner_left">
              会员注册
            </div>
            <div class="login_banner_right">
              <span>已有会员？</span>
              <span
                class="immediatelyLogin"
                @click="goLogin"
              >马上登录</span>
            </div>
          </div>
          <!-- 账号登录 -->
          <div class="login_banner_input">
            <div class="login_banner_item">
              <img
                class="login_banner_item_img"
                src="../../image/phone.png"
                alt=""
                srcset=""
              >
                <span class="span_text">手机号码</span>
                <input
                  type="text"
                  class="numberPhone"
                  placeholder="请输入您的手机号码"
                >
            </div>
            <div class="login_banner_item">
              <img
                class="login_banner_item_img"
                src="../../image/phone.png"
                alt=""
                srcset=""
              >
                <span class="span_text">手机验证码</span>
                <input
                  type="text"
                  class="numberPhone span_textLeft"
                  placeholder="请输入手机验证码"
                >
                  <button
                    disabled="disabled"
                    class="btnDisabled"
                  >获取短信验证码</button>
            </div>
            <div class="login_banner_item">
              <img
                class="login_banner_item_img"
                src="../../image/yanzheng.png"
                alt=""
                srcset=""
              >
                <span class="span_text ">设置密码</span>
                <input
                  type="text"
                  class="numberPhone "
                  placeholder="请输入您的密码"
                >
            </div>
            <div class="login_banner_item">
              <img
                class="login_banner_item_img"
                src="../../image/password.png"
                alt=""
                srcset=""
              >
                <span class="span_text">确认密码</span>
                <input
                  type="text"
                  class="numberPhone"
                  placeholder="请再次确认您的密码"
                >
            </div>
          </div>
          <!-- 提交登录 -->
          <div class="btnSubmit">
            <button class="btnSubmit_box">提交</button>
          </div>
    </div>
    <!-- 登录 -->
    <div
      class="login_banner_box"
      v-else
    >
      <div class="login_banner_nav">
        <div
          @click="clickChanged(1)"
          class="login_banner_left login_banner_left_center"
        >
          <div :class="{saomaDengLu:true,saomaDengLuColorBorder:saomaDengLuColorr}">
            扫码登录
          </div>
      </div>
      <div
        @click="clickChanged(2)"
        class="login_banner_right login_banner_left_center"
      >
        <div :class="{saomaDengLu:true,saomaDengLuColorBorder:saomaDengLuColor}">
          账号登录
        </div>
  </div>
  </div>
  <!-- 账号登录 -->
  <div
    class="login_banner_input"
    v-if="saomaDengLuColor"
  >
    <div class="login_banner_item">
      <img
        class="login_banner_item_img"
        src="../../image/phone.png"
        alt=""
        srcset=""
      >
        <span class="span_text">手机号码</span>
        <input
          type="text"
          class="numberPhone"
          placeholder="请输入您的手机号码"
        >
    </div>
    <div class="login_banner_item">
      <img
        class="login_banner_item_img"
        src="../../image/password.png"
        alt=""
        srcset=""
      >
        <span class="span_text">密码</span>
        <input
          type="text"
          class="numberPhone"
          placeholder="请输入您的密码"
        >
    </div>
    <div class="btnLoginsubmit">
      <button
        disabled="disabled"
        class="btnLogin"
      >登录</button>
    </div>
    <div class="zhuc" @click="showzhc">
      马上注册
    </div>
    </div>
    <!-- 扫码登录 -->
    <div
      class="login_banner_input"
      v-if="saomaDengLuColorr"
    >
      <div class="login_banner_item login_banner_item_clear">
        <img
          src="../../image/loginewm.png"
          class="login_banner_item_img"
          alt=""
          srcset=""
        >
          <div class="login_banner_item_img_iocn_right">
            <img
              src="../../image/soyisaoIcon.png"
              class="login_banner_item_img_iocn"
              alt=""
              srcset=""
            >
              <span>打开微信扫一扫</span>
          </div>
      </div>
      </div>
      </div>
      </div>

      <!-- 底部 -->
      <div class="foolter">
        <Footer />
      </div>
      </div>
</template>
<script>
import "@/assets/comm/comm.css";
import Footer from "../footer/footer";
export default {
  data() {
    return {
      register: true,
      saomaDengLuColor: true,
      saomaDengLuColorr: false
    };
  },
  created() {
   const id=this.$route.query.id
   if(id==2){
     this.register=false
   }
  },
  methods: {
    goLogin() {
      this.register = !this.register;
    },
    clickChanged(id) {
      if (id == 1) {
        this.saomaDengLuColorr = true;
        this.saomaDengLuColor = false;
      } else {
        this.saomaDengLuColor = true;
        this.saomaDengLuColorr = false;
      }
    },
    showzhc(){
      this.register=true
    }
  },
  components: {
    Footer
  }
};
</script>
<style lang="less" scoped>
.login_conanter {
  width: 100%;
  .login_box {
    height: 106px;
    line-height: 106px;
    background-color: #fff;
    display: flex;
    align-items: center;
    .login_box_img {
      width: 148px;
      height: 54px;
      margin-right: 27px;
    }
    .login_box_right {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
    }
  }
  .login_banner {
    width: 100%;
    position: relative;
    height: 605px;
    .login_banner_img {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    .login_banner_box {
      position: absolute;
      right: 394px;
      width: 442px;
      top: 60px;
      height: 485px;
      background-color: #fff;
      padding-left: 18px;
      padding-right: 18px;
      .login_banner_nav {
        height: 57px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ffeae2;

        justify-content: space-between;
        .login_banner_left {
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ff5000;
        }
        .login_banner_right {
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #999999;
          .immediatelyLogin {
            color: #ff5000;
            cursor: pointer;
          }
        }
        .login_banner_left_center {
          width: 220px;
          display: flex;
          justify-content: center;
          border-right: 1px solid #d8d8d8;
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          cursor: pointer;
          height: 100%;
          .saomaDengLu {
            color: #999999;
            width: 99px;
            height: 57px;
            line-height: 57px;
          }
          .saomaDengLuColorBorder {
            color: #ff5000;
            border-bottom: 2px solid #ff5000;
          }
        }
        .login_banner_left_center:last-child {
          border-right: 0px solid #d8d8d8;
        }
      }
      .login_banner_input {
        // height: 80%;
        .login_banner_item {
          margin-top: 20px;
          width: 409px;
          height: 38px;
          box-sizing: border-box;
          border: 1px solid #bcbcbc;
          display: flex;
          align-items: center;
          .login_banner_item_img {
            margin-left: 13px;
          }
          .span_text {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #333333;
            margin-left: 11px;
          }

          .numberPhone {
            outline: none;
            border: 0px;
            margin-left: 28px;
          }
          .span_textLeft {
            margin-left: 14px;
          }
          .btnDisabled {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #ff5000;
            opacity: 0.8;
            height: 100%;
            margin-left: 20px;
            cursor: pointer;
            border: 0px;
            border-left: 1px solid #b3b3b3;
          }
          input::-webkit-input-placeholder {
            /* WebKit browsers */
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #999999;
            opacity: 0.8;
          }
          input:-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #999999;
            opacity: 0.8;
          }
          input::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #999999;
            opacity: 0.8;
          }
          input:-ms-input-placeholder {
            /* Internet Explorer 10+ */
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #999999;
            opacity: 0.8;
          }
        }
        .login_banner_item_clear {
          border: 0px;
          height: 408px;
          display: flex;
          flex-direction: column;
          // justify-content: center;
          align-items: center;
          padding-top: 18px;
          .login_banner_item_img {
            width: 253px;
            height: 260px;
          }
          .login_banner_item_img_iocn_right {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 48px;
            span {
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #ff5000;
              margin-left: 5px;
            }
          }
        }
        .btnLoginsubmit {
          width: 408px;
          height: 38px;
          background: #ff4b00;
          margin-top: 223px;
          .btnLogin {
            width: 100%;
            height: 100%;
            background: #ff4b00;
            border: 0;
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #ffffff;
            opacity: 0.8;
            cursor: pointer;
          }
        }
        .zhuc {
          margin-top: 19px;
          width: 100%;
          text-align: center;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ff4b00;
          // opacity: 0.8;
          cursor: pointer;
        }
      }
      .btnSubmit {
        width: 100%;
        margin-top: 107px;
        .btnSubmit_box {
          width: 100%;
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
          opacity: 0.8;
          background-color: #ff4b00;
          border: 0;
          height: 38px;
          width: 409px;
          cursor: pointer;
        }
      }
    }
  }
  .foolter {
    width: 100%;
  }
}
</style>
